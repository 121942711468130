import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faTags,
} from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";

export default function ProvidersPage({ data }) {
  const {
    totaleProviders: { totalCount },
    allProvidersJson: { nodes: providers },
  } = data;

  return (
    <Layout
      pageTitle="Providers ECM"
      description="Elenco di tutti i Providers nazionali e dei rispettivi corsi ECM accreditati"
    >
      <section className="position-relative">
        <StaticImage
          src="../../images/header-provider.jpg"
          className="img-header-provider"
          alt={`Providers`}
          title={`Providers`}
        />
        <div className="container-fluid px-0 py-5 container-absolute">
          <div className="container py-5">
            <h1 className="text-center text-white">Providers ECM</h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scopri tutti i {totalCount} Providers nazionali e dei rispettivi corsi ECM
              accreditati
            </p>
          </div>
        </div>
      </section>
      <ProvidersGrid providers={providers} />
    </Layout>
  );
}

function ProvidersGrid({ providers }) {
  const [pagina, setPagina] = useState(1);

  const providersPage = providers.slice(0, pagina * 20);

  return (
    <>
      <div class="container-fluid my-5">
        <div class="row">
          {providersPage.map((provider) => (
            <ProviderGridItem key={provider.id} provider={provider} />
          ))}
        </div>
      </div>
      {providers.length > 0 && providersPage.length < providers.length && (
        <div className="container-fluid">
          <div className="row px-5 pt-3 pb-5">
            <div className="col-12 text-center">
              <button
                className="btn btn-outline-primary"
                onClick={() => setPagina((prev) => prev + 1)}
              >
                Carica altri provider
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ProviderGridItem({ provider }) {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
        <div className="card card-provider border-secondary mb-3">
          <div className="card-header">
            Provider N. {provider.id_provider} - {provider.ragione_sociale}
          </div>
          <div className="card-body text-primary">
            <p className="card-text text-center">
              <span className="font-weight-bold">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />{" "}
                {provider.sede_legale}
                <br />
                <FontAwesomeIcon icon={faTags} className="mr-1" /> Tipologia
                formazione: {provider.tipo_formazione}
                <br />
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-1"
                /> Email:{" "}
                <a
                  href={`mailto:${provider.email}`}
                  title={`Scrivi a ${provider.ragione_sociale}`}
                >
                  {provider.email}
                </a>
                <br />
                <FontAwesomeIcon icon={faPhone} className="mr-1" /> Telefono:
                <a
                  href={`tel:+39${provider.telefono}`}
                  title={`Telefona a ${provider.ragione_sociale}`}
                >
                  {provider.telefono}
                </a>
              </span>
            </p>
            <Link to={provider.providerPath} className="btn btn-warning">
              Vedi dettaglio
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query {
    totaleProviders: allProvidersJson {
      totalCount
    }
    allProvidersJson(sort: { fields: [provider_top, ragione_sociale], order: [ASC, ASC] }) {
      nodes {
        id
        id_provider
        ragione_sociale
        sede_legale
        telefono
        email
        tipo_formazione
        providerPath: gatsbyPath(
          filePath: "/providers-agenas/{ProvidersJson.slug}"
        )
      }
    }
  }
`;
